<template>
  <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-4">
    <AppCard v-for="sponsor of sponsors" :key="sponsor._uid" :hide-footer="true" :hide-header="true">
      <template v-slot:card-image>
        <img class="h-20" :src="sponsor.image.filename" :alt="sponsor.name" />
      </template>
      <template v-slot:card-body>
        <div class="text-center font-bold text-xl">{{ sponsor.name }}</div>
      </template>
    </AppCard>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { SponsorModel } from '@/models/sponsor.model';
import AppCard from '@/components/ui/AppCard.vue';

export default defineComponent({
  components: { AppCard },
  props: {
    sponsors: {
      type: Array as PropType<SponsorModel[]>,
      default: (): Array<SponsorModel> => [],
    },
  },
});
</script>
