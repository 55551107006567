
import { defineComponent, PropType } from 'vue';
import { SponsorModel } from '@/models/sponsor.model';
import AppCard from '@/components/ui/AppCard.vue';

export default defineComponent({
  components: { AppCard },
  props: {
    sponsors: {
      type: Array as PropType<SponsorModel[]>,
      default: (): Array<SponsorModel> => [],
    },
  },
});
