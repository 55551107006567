<template>
  <AppTitle>Sponsoren</AppTitle>
  <SponsorsList :sponsors="sponsors" />
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import { StoryData } from 'storyblok-js-client';
import { SponsorModel } from '@/models/sponsor.model';
import SponsorsList from '@/components/ui/SponsorsList.vue';
import AppTitle from '@/components/ui/AppTitle.vue';
import { fetchSponsors } from '@/composables/api';

export default defineComponent({
  components: { AppTitle, SponsorsList },
  setup() {
    const sponsors = ref<SponsorModel[]>();

    onMounted(async () => {
      const stories: StoryData<SponsorModel>[] = await fetchSponsors();

      sponsors.value = stories.map((sponsor: StoryData<SponsorModel>) => sponsor.content);
    });

    return { sponsors };
  },
});
</script>
